
import './Syst.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


function SystSample(){
return (
    <>
<Container >
                
<Row><p className='textContent'>雲端系統介紹</p></Row>

<Row><p className='textContent'>版本一</p></Row>
                <Row>
                    <Col>
                        <img className="viewImage" src='artv1.jpg' alt="basicConcept"></img>
                    </Col>
                </Row>
                <Row><p className='textContent'>介面</p></Row>
                <Row>
                    <Col>
                        <img className="viewImage" src='SystShow.jpg' alt="basicConcept"></img>
                    </Col>
                </Row>
                <Row>
                    <Col ><p className='dataLeft'>相關數據：</p></Col>
                </Row>
                <Row>
                    <Col><p className='dataLeft'>上市公司數量:</p></Col><Col><p className='dataRight'>約2,600間</p></Col>
                </Row>
                <Row>
                    <Col><p className='dataLeft'>每日新增資料量:</p></Col><Col><p className='dataRight'>約50萬條/天</p></Col>
                </Row>
                <Row>
                    <Col><p className='dataLeft'>資料日數:</p></Col><Col><p className='dataRight'>988天</p></Col>
                </Row>
                <Row>
                    <Col><p className='dataLeft'>資料總量:</p></Col><Col><p className='dataRight'>4.9億條 </p></Col>
                </Row>
                <Row>
                    <Col><p className='dataLeft'>每次查詢速度:</p></Col><Col><p className='dataRight'>約1.5秒</p></Col>
                </Row>
                

                    
                    
                    
                    
                    


                

                <Row><p className='textContent'>版本二</p></Row>
                <Row>
                    <Col>
                        <img className="viewImage" src='artv3.jpg' alt="basicConcept"></img>
                    </Col>
                </Row>

                
                

                </Container>
    
    
    </>
)

}

export default SystSample;