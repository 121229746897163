
import "./Playai.css"
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useRef, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Animated } from "react-animated-css";



function Playai() {

    const [isLoading, setIsLoading] = useState(false);
    const [err, setErr] = useState('');

    
    const [questionString, setQuestionString] = useState("hello~");
    const [answerString, setAnswerString] = useState("等待第一次回覆");


    const [answered, setAnswered] = useState(-99);


    // const handleClick_post = async () => {
    //     setAnswered(-99);
    //     setIsLoading(true);
    //     fetch("https://api.ipify.org?format=json")
    //         .then((response) => response.json())
    //         .then((data) => {
    //             // construct post request body
    //             const requestBody = {
    //                 userIP: data.ip,
    //                 message: 'hello',
    //                 timestamp: new Date().toISOString(),
    //             };
    //             console.log(requestBody);
    //         })
    // };

    const handleClick = async () => {
        setAnswered(-99);
        setIsLoading(true);
        
        var request_string = 'https://wehapimaid.com:81/tak?quest1=' + questionString+'&askwho=teacher';
        

        console.log(request_string);
        try {
            const response = await fetch(request_string, {

                method: 'GET'
            });

            if (!response.ok) {
                throw new Error(`Error! status: ${response.status}`);
            }

            const result = await response.json();


            console.log('result is: ', JSON.stringify(result, null, 4));
            console.log(typeof (JSON.stringify(result, null, 4)));
            console.log(result.pred_y);




            setAnswered(1);
            var myStr = "";
            myStr=result.answerCollect.message.content.replaceAll("\n","<br>");
            setAnswerString(myStr);
            // setAnswerString(result.answerCollect.message.content);



        } catch (err) {
            setAnswered(1);
            setAnswerString("接收資料有問題,可能伺服器繁忙喔~");
            setErr(err.message);
        } finally {
            setIsLoading(false);
        }
    };






    function handleSubmit(e) {
        e.preventDefault();
        handleClick();
        console.log(inputRef.current.value);


    }
    const inputRef = useRef(19900);

    return (
        <>


            <Container >

                <Row><p className='textContent'>連接GPT</p></Row>
                <Row><p className='textContent'>—————————————————</p></Row>
            </Container>
            <div>
                <Form onSubmit={handleSubmit}>
                    <Container >

                        <Row><p className='textContent'>對話位置</p></Row>

                        <Row>
                            <Col>問題:</Col>
                            <Col>
                                <input ref={inputRef} type="text" id="question" name="question" value={questionString} onChange={event => setQuestionString(event.target.value)} />
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Button variant="primary" type='submit' >
                                    等待GPT回應!
                                </Button>
                            </Col>
                        </Row>
                    </Container>

                </Form>


                {answered === 1 &&
                    <Container >

                        <Row><p className='textContent'>回應</p></Row>
                        <Row>

                            <Col>
                                <Animated animationIn="bounceInLeft" animationOut="fadeOut" isVisible={true}>
                                    {/* {answerString} */}
                                    <div dangerouslySetInnerHTML={{ __html: answerString }} />
                                </Animated>
                            </Col>
                        </Row>
                    </Container>

                }

                {isLoading ? (
                    <Spinner animation="border" />
                ) : (
                    <div></div>
                )
                }
                <Container >
                    <Row>

                        <Col>
                            <p>-----------------------------</p>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}
export default Playai;





