
import './Myview.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function MyviewSample(){
    return (
        <>



                <Container >
                
                <Row><a href='./cv2022.pdf'>個人覆歷</a></Row>
                <Row><p className='textContent'>以持股變化作數據分析</p></Row>
                <Row><p className='textContent'>一般人</p></Row>
                <Row>
                    <Col>
                        <img className="viewImage" src='normalPeople.jpg' alt="basicConcept"></img>
                    </Col>
                </Row>

                <Row>
                    <Col><p className='textContent'>搜尋，排序功能</p></Col>
                    
                </Row>
                
                <Row>
                    <Col>
                        <img className="viewImage" src='searchForm.jpg' alt="basicConcept"></img>
                    </Col>
                </Row>

                <Row>
                    <Col><p className='textContent'>專業人士</p></Col>
                    
                </Row>
                <Row><Col><p>1.中遠海控</p></Col></Row>
                <Row>
                    <Col>
                        <img className="viewImage" src='1919north1.jpg' alt="basicConcept"></img>
                    </Col>
                </Row>
                <Row><p >一年後(5倍利潤)</p></Row>
                <Row>
                    <Col>
                        <img className="viewImage" src='1919north2.jpg' alt="basicConcept"></img>
                    </Col>
                </Row>
                <Row><p >新聞(後知後覺)</p></Row>
                <Row>
                    <Col>
                        <img className="viewImage" src='1919news.jpg' alt="basicConcept"></img>
                    </Col>
                </Row>
                <Row><p className='textContent'>建議：深入研究各種週期性現象</p></Row>
                
                <Row><p className='textContent'>—————————————————</p></Row>
                
                <Row><Col><p className='textContent'>2.阿里巴巴</p></Col></Row>
                <Row><Col><p>發現異常(週期性活動)</p></Col></Row>
                
                <Row>
                    <Col>
                        <img className="viewImage" src='9988flower3month.jpg' alt="basicConcept"></img>
                    </Col>
                </Row>
                <Row><p >延伸(1年)</p></Row>
                <Row>
                    <Col>
                        <img className="viewImage" src='9988flower1year.jpg' alt="basicConcept"></img>
                    </Col>
                </Row>
                <Row><p >源頭及相關新聞</p></Row>
                <Row>
                    <Col>
                        <img className="viewImage" src='9988begin.jpg' alt="basicConcept"></img>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <img className="viewImage" src='9988news.jpg' alt="basicConcept"></img>
                    </Col>
                </Row>
                <Row><p className='textContent'>建議：等待週期性現象結束再作決定</p></Row>
                
                <Row><p className='textContent'>—————————————————</p></Row>
                </Container>
        
        
        </>
    )
    
    }
    
    export default MyviewSample;