import React, { useState } from "react";
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';


function OffcanvasExample(props) {

    
    const { setWhichPage } = props;
    
    return (
      <>
        
          <Navbar fixed="top" collapseOnSelect key="md" bg="light" variant="light" expand="md" className="mb-3" >
            <Container fluid>
              <Navbar.Brand href="#">永個人簡介</Navbar.Brand>
              <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-$"md"`} />
              <Navbar.Offcanvas
                id={`offcanvasNavbar-expand-$"md"`}
                aria-labelledby={`offcanvasNavbarLabel-expand-$"md"`}
                placement="end"
              >
                <Offcanvas.Header closeButton >
                  <Offcanvas.Title id={`offcanvasNavbarLabel-expand-$"md"`}>
                    章節
                  </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <Nav className="justify-content-end flex-grow-1 pe-3">
                    <Nav.Link href="#Syst" onClick={() => {setWhichPage("Syst");}}>資訊系統介紹</Nav.Link>
                    <Nav.Link href="#Myview" onClick={() => {setWhichPage("Myview");}}>數據分析。思維</Nav.Link>
                    <Nav.Link href="#Upanddown" onClick={() => {setWhichPage("Upanddown");}}>持續進步</Nav.Link>
                    <Nav.Link href="#Playai" onClick={() => {setWhichPage("Playai");}}>嘗試一下gpt</Nav.Link>
                    {/* <NavDropdown
                      title="Dropdown"
                      id={`offcanvasNavbarDropdown-expand-$"md"`}
                    >
                      <NavDropdown.Item href="#action3">Action</NavDropdown.Item>
                      <NavDropdown.Item href="#action4">
                        Another action
                      </NavDropdown.Item>
                      <NavDropdown.Divider />
                      <NavDropdown.Item href="#action5">
                        Something else here
                      </NavDropdown.Item>
                    </NavDropdown> */}
                  </Nav>
                  {/* <Form className="d-flex">
                    <Form.Control
                      type="search"
                      placeholder="Search"
                      className="me-2"
                      aria-label="Search"
                    />
                    <Button variant="outline-success">Search</Button>
                  </Form> */}
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </Container>
          </Navbar>
        
      </>
    );
  }
  
  export default OffcanvasExample;