import logo from './../../logo.svg';
import './App.css';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navtop from './../Navtop/Navtop';
import Myview from './../Myview/Myview';
import Syst from './../Syst/Syst';
import Upanddown from './../Upanddown/Upanddown';
import Playai from './../Playai/Playai';
import { useState } from 'react';

function App() {

  const [whichPage, setWhichPage]=useState("Myview");
  return (
    
    <div className="App">
<head>
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.5.2/animate.min.css"></link>
  {/* <meta http-equiv="Content-Security-Policy" content="upgrade-insecure-requests"></meta> */}
</head>
      <Navtop {...{setWhichPage}}></Navtop>
      <div className='text-light bg-dark mainContent'>
        {whichPage === "Myview" ? (
          <Myview></Myview>
        ):(<div></div>)
        }
        {whichPage==="Syst"?(
          <Syst></Syst>
        ):(<div></div>)
        }
        {whichPage==="Upanddown"?(
          <Upanddown></Upanddown>
        ):(<div></div>)
        }
        {whichPage==="Playai"?(
          <Playai></Playai>
        ):(<div></div>)
        }
      </div>
      <Container>
      <Row>
        <Col><p>感謝查看</p></Col>
      </Row>
    </Container>

    
    </div>
  );
}

export default App;
