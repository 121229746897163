
import "./Upanddown.css"
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import {useRef,useState} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Animated} from "react-animated-css";



function Upanddown(){
    const [data, setData] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [err, setErr] = useState('');
    const [openPoint,setOpenPoint]=useState(19800);
    const [closePoint,setClosePoint]=useState(19900);
    
    
    const [predY,setPredY]=useState(-99);
    const [predicted,setPredicted]=useState(false);



    const handleClick = async () => {
        setPredY(-99);
        setIsLoading(true);
        setPredicted(true);
        var highPoint=Math.max(closePoint,openPoint)+100;
        var lowPoint=Math.min(closePoint,openPoint)-100;
        
        var request_string='https://wehapimaid.com:8080/asktomorrow?high='+highPoint+'&low='+lowPoint+'&close='+closePoint+'&open='+openPoint;
        console.log(request_string);
        try {
            const response = await fetch(request_string, {
            
            method: 'GET'
          });
    
          if (!response.ok) {
            throw new Error(`Error! status: ${response.status}`);
          }
    
          const result = await response.json();

    
          console.log('result is: ', JSON.stringify(result, null, 4));
          console.log(typeof(JSON.stringify(result, null, 4)));
          console.log(result.pred_y);




          setPredY(result.pred_y);

        //   console.log(result.Close);
        //   console.log(typeof(result))
        //   console.log(typeof(result.Close))
        //   console.log(result.Close[0])
          
        //   console.log(result.Close.length)
        //   var array=Object.keys(result)
        //     .map(function(key){
        //         return result[key];
        //         }  

        //     );
    



          
        } catch (err) {
            setPredY(-2);
          setErr(err.message);
        } finally {
          setIsLoading(false);
        }
      };






    function handleSubmit(e) {
        e.preventDefault();
        handleClick();
        console.log(inputRef.current.value);


      }
const inputRef=useRef(19900);
    
return (
    <>


<Container >
                
                <Row><p className='textContent'>持續學習</p></Row>
                
                <Row>
                    <Col>
                        <img className="viewImage" src='ML_show.jpg' alt="basicConcept"></img>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <img className="viewImage" src='s3_show1.jpg' alt="basicConcept"></img>
                    </Col>
                </Row>

                
                <Row><p className='textContent'>—————————————————</p></Row>
                </Container>
        <div>
        <Form onSubmit={handleSubmit}>
        <Container >
                
                <Row><p className='textContent'>預測系統展示</p></Row>
                
                <Row>
                    <Col>恆指收市價:</Col>
                    <Col>
                    <input ref={inputRef} type="text" id="close_price" name="close_price"  value={closePoint} onChange={event => setClosePoint(event.target.value)}/>            
                    </Col>
                </Row>
                <Row>
                <Col>恆指開市價:</Col>
                    <Col>
                    <input ref={inputRef} type="text" id="open_price" name="open_price"  value={openPoint} onChange={event => setOpenPoint(event.target.value)}/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                    <Button variant="primary" type='submit' >
                        預測明天收市升跌概況
                    </Button>
                    </Col>
                </Row>
        </Container>

    </Form>


{predY===1 &&
    <Container >
                
    <Row><p className='textContent'>預測結果</p></Row>
    <Row>
        
        <Col>
        <Animated animationIn="bounceInLeft" animationOut="fadeOut" isVisible={true}>
                <p >預計升幅100點以上</p>
                </Animated>
        </Col>
    </Row>
    </Container>

}
{predY===0 &&
    <Container >
                
    <Row><p className='textContent'>預測結果</p></Row>
    <Row>
        
        <Col>
        <Animated animationIn="bounceInLeft" animationOut="fadeOut" isVisible={true}>
                <p>預計波幅正負100點以內</p>
                </Animated>
        </Col>
    </Row>
    </Container>

}
{predY===-1 &&
    <Container >
                
    <Row><p className='textContent'>預測結果</p></Row>
    <Row>
        
        <Col>
        <Animated animationIn="bounceInLeft" animationOut="fadeOut" isVisible={true}>
                <p>預計跌幅100點以上</p>
                </Animated>
        </Col>
    </Row>
    </Container>

}
{predY===-2 &&
    <Container >
                
    <Row><p className='textContent'>預測結果</p></Row>
    <Row>
        
        <Col>
        <Animated animationIn="bounceInLeft" animationOut="fadeOut" isVisible={true}>
                <p>敬請期待</p>
                </Animated>
        </Col>
    </Row>
    </Container>

}



    {isLoading?(
            <Spinner animation="border" />
        ):(
             
            <div></div>
            
        )
    }
            

        <Container >          
            <Row>
                    
                <Col>
                    <p>-----------------------------</p>
                </Col>
            </Row>
        </Container>
            
        </div>
    
    </>
)

}
export default Upanddown;





